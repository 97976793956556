<template>
  <v-container fluid>
    <v-card>
      <v-toolbar
        color="secondary"
        flat
      >
        <v-toolbar-title>Reports</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>

      <v-card-text>
        <!-- start of filters -->
        <v-row
          dense
          align-content="space-around"
        >
          <v-col cols="12">
            <v-card-text class="pa-0 font-weight-bold">Filters</v-card-text>
            <v-card-text class="pa-0 font-weight-bold">Date Range</v-card-text>
          </v-col>
          <v-col cols="3">
            <DatePicker
              @date="fromDate"
              :myDate="bpMasterData.fromDate"
              :title="`Date`"
            ></DatePicker>
          </v-col>
          <v-col cols="3">
            <DatePicker
              @date="toDate"
              :myDate="bpMasterData.toDate"
              :title="`Date`"
            ></DatePicker>
          </v-col>
          <v-col cols="3">
            <v-btn
              color="accent"
              @click="filterOutlets"
            >Filter</v-btn>
          </v-col>
        </v-row>
        <!-- end of filters -->
      </v-card-text>

      <v-card-text>
        <!-- start of datatable -->
        <v-row>
          <v-col cols="6">
            <v-card-text class="pa-0 font-weight-bold">Outlets - Outlets mapped</v-card-text>
            <v-card-text class="pa-0">All mapped outlets</v-card-text>
          </v-col>
          <v-col
            cols="4"
            offset-md="2"
          >
            <v-tooltip
              top
              color="grey lighten-5"
            >
              <!-- <template v-slot:activator="{ on, attrs }">
                <ExcelExport
                  :data="bpMasterData"
                  :fields="reportHeaders"
                  worksheet="Outlets Summary"
                  name="Outlets - Outlets Mapped.xls"
                >
                  <v-icon v-bind="attrs" v-on="on" color="green">mdi-file-excel-outline</v-icon>
                </ExcelExport>
              </template> -->
              <span class="black--text">Export to excel</span>
            </v-tooltip>
            <v-tooltip
              bottom
              color="grey lighten-5"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  @click="generatePDF()"
                  color="red"
                >mdi-file-pdf-outline</v-icon>
              </template>
              <span class="black--text">Export to PDF</span>
            </v-tooltip>
          </v-col>
        </v-row>

        <v-row>
          <v-col
            cols="12"
            sm="12"
            md="12"
          >
            <v-data-table
              :headers="headers"
              :items="bpMasterData"
              :search="search"
            >
              <template v-slot:item.CardName="{ item }">
                <v-btn
                  color="accent"
                  text
                  :to="`/bp-master-data/${item.id}`"
                >{{ item.CardName }}</v-btn>
              </template>

              <!-- sales employee template -->
              <template v-slot:item.employees="props">
                <v-btn
                  color="blue lighten-1"
                  text
                  small
                  :to="`/sales/employee/${props.item.employees.id}`"
                >{{ props.item.employees.SlpName }}</v-btn>
              </template>
              <!-- end -->
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import jsPDF from "jspdf";
import "jspdf-autotable";
export default {
  data: () => ({
    loading: false,
    search: "",
    bpMasterData: [],
    reportHeaders: {
      "Date mapped": "CardCode",
      "Outlet name": "CardName",
      Region: "territory.descript",
      "Sub Region": "territory.parent.descript",
      Category: "CardCode",
      Distributor: "Distributor",
      Address: "Address",
      Longitude: "Longitude",
      Latitude: "Latitude",
      "Contact Person": "CntctPrsn",
      "Phone number": "Phone1",
      "Mapped by": "SlpCode",
    },
    headers: [
      { text: "Date mapped", value: "CardCode" },
      { text: "Outlet Name", value: "CardName" },
      { text: "Region", value: "territory.descript" },
      { text: "Sub Region", value: "territory.parent.descript" },
      { text: "Category", value: "CardCode" },
      { text: "Distributor", value: "Distributor" },
      { text: "Address", value: "Address" },
      { text: "Longitude", value: "Longitude" },
      { text: "Latitude", value: "Latitude" },
      { text: "Contact person", value: "CntctPrsn" },
      { text: "Phone number", value: "Phone1" },
      { text: "Mapped by", value: "employees" },
    ],
  }),
  methods: {
    getBpMaster() {
      const self = this;
      self.loading = true;
      this.$store
        .dispatch("get", `/bp_report`)
        .then((res) => {
          console.log(res, "r");
          self.bpMasterData = res;
          self.loading = false;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    fromDate(date) {
      this.bpMasterData.fromDate = date;
    },
    toDate(date) {
      this.bpMasterData.toDate = date;
    },
    filterOutlets() {
      const data = {
        fromDate: this.bpMasterData.fromDate,
        toDate: this.bpMasterData.toDate,
      };

      let url = `/searchOutlets`;
      const self = this;
      self.loading = true;
      this.$store
        .dispatch("post", { url, data })
        .then((res) => {
          self.bpMasterData = res;
          self.loader = false;
        })
        .catch((err) => {
          console.log(err, "err");
          // this.$refs.snackbar.show(err, "red");
        });
    },
    generatePDF() {
      let pdfName = "Outlets - Outlets mapped";
      const doc = new jsPDF();
      let keys = Object.keys(this.reportHeaders);
      // let obj = this.headers;
      let data = this.bpMasterData;

      data.forEach((element) => {
        // var temp = [element.index, element.id];
        return element;
        // console.log(element, "value");
      });

      doc.autoTable({ head: [keys], body: [data] });
      // console.log(data, "table");
      doc.save(pdfName + ".pdf");
    },
  },
  created() {
    this.getBpMaster();
  },
};
</script>
